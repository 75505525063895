/* eslint-disable react-hooks/rules-of-hooks */
import * as R from 'ramda'
import {arrayOf, shape} from 'prop-types'
import {graphql} from 'gatsby'
import {Grid} from '@mui/material'
import loadable from '@loadable/component'
import React, {useEffect} from 'react'

import {
  FilterPropTypes,
  FiltersContainerPropTypes,
  keyplaysPropTypes,
  locationPropTypes,
  pageContextPropTypes,
  partnerHeaderPropTypes,
  playbookHeaderPropTypes,
} from 'helpers/propTypes'

import Filter from 'components/UI/Filter'
import FiltersContainer from 'components/UI/FiltersContainer'
import Keyplays from 'components/PlayBook/Keyplays'
import PartnersFilter from 'components/PlayBook/Filters/PartnersFilter'
import Section from 'components/UI/Section'
import SectionComponent from 'components/UI/SectionComponent'
import SEO from 'components/seo'
import SwitchHeader from 'components/PlayBook/SwitchHeader'
import useHashConverter from 'hooks/useHashConverter'
import useHashReader from 'hooks/useHashReader'
import useIsMobile from 'hooks/useIsMobile'
import usePartnerHeader from 'hooks/usePartnerHeader'
import usePlaybookFiltersData from 'hooks/usePlaybookFiltersData'
import useScrollIntoView from 'hooks/useScrollIntoView'
import useTagsObject from 'hooks/useTagsObject'

const PlayBook = ({pageContext, data, location}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))
  const {hash} = location

  const {
    metaTitle,
    metaDescription,
    pageTitle,
    partnersShortDescription,
    searchPlaceholder,
    filterByTagsLabel,
    filterByIndustryLabel,
    filterByDepartmentLabel,
    childContentfulPlaybookShortDescriptionTextNode,
    filterByPartnerLabel,
    filterByServiceLabel,
    filterSeeMoreLabel,
    filterSeeLessLabel,
    eulaLink,
    sections,
  } = R.pathOr(null, ['allContentfulPlaybook', 'edges', 0, 'node'], data)

  const keyplays = R.pathOr(null, ['allContentfulKeyPlay', 'nodes'], data)

  const partnersHeaderTitle = R.pathOr(
    '',
    ['allContentfulMenuItem', 'edges', 0, 'node', 'title'],
    data,
  )

  const {
    allTagsObjects,
    newTagsPartnersObjects,
    newTagsServicesObjects,
    newTagsDepartmentsObjects,
    newTagsIndustriesObjects,
    newAllPartnersObjects,
    newTagsTagsObjects,
    allPartnersToggleTag,
    services,
  } = usePlaybookFiltersData(data)

  const isMobile = useIsMobile()
  const [hashConditions] = useHashReader(location)
  const [selectedTags] = useHashConverter()
  const [selectedTagsObjects] = useTagsObject(selectedTags, allTagsObjects)
  const [partnerHeaderContent] = usePartnerHeader(
    newAllPartnersObjects,
    selectedTags,
  )

  const [currentTagTags] = useTagsObject(selectedTags, newTagsTagsObjects)
  const [currentServicesTags] = useTagsObject(
    selectedTags,
    newTagsServicesObjects,
  )
  const [currentIndustriesTags] = useTagsObject(
    selectedTags,
    newTagsIndustriesObjects,
  )
  const [currentDepartmentsTags] = useTagsObject(
    selectedTags,
    newTagsDepartmentsObjects,
  )
  const [currentPartnersTags] = useTagsObject(
    selectedTags,
    newTagsPartnersObjects,
  )

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  if (hash === '#playbook') {
    useScrollIntoView(hash)
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <SectionComponent sections={sections} />
      <SwitchHeader
        id="playbook"
        partner={partnerHeaderContent}
        pageTitle={pageTitle}
        description={childContentfulPlaybookShortDescriptionTextNode}
        partnersTitle={partnersHeaderTitle}
        partnersShortDescription={partnersShortDescription}
        hashConditions={hashConditions}
        eulaLink={eulaLink}
      />
      <Decorations>
        <Decoration webSiteName="keyrus" color="orange" right={0} top={130} />
        <Decoration webSiteName="keyrus" color="blue" left={0} top={154} />
      </Decorations>
      <Section hasPaddingTop={false}>
        <Grid
          container
          spacing={1}
          style={isMobile ? {marginTop: 0} : {marginTop: 70}}
        >
          <Grid item xs={12} sm={6} md={4} style={{padding: 0}}>
            <FiltersContainer searchPlaceholder={searchPlaceholder} hasBorder>
              <Filter
                currentTags={currentTagTags}
                filterLabel={filterByTagsLabel}
                filterSeeMoreLabel={filterSeeMoreLabel}
                filterSeeLessLabel={filterSeeLessLabel}
                tags={newTagsTagsObjects}
                hasObjectsTags
              />

              <Filter
                currentTags={currentIndustriesTags}
                filterLabel={filterByIndustryLabel}
                filterSeeMoreLabel={filterSeeMoreLabel}
                filterSeeLessLabel={filterSeeLessLabel}
                tags={newTagsIndustriesObjects}
                hasObjectsTags
              />
              <Filter
                currentTags={currentDepartmentsTags}
                filterLabel={filterByDepartmentLabel}
                filterSeeMoreLabel={filterSeeMoreLabel}
                filterSeeLessLabel={filterSeeLessLabel}
                tags={newTagsDepartmentsObjects}
                hasObjectsTags
              />
              {/* <PartnersFilter
                currentTags={currentPartnersTags}
                filterByPartnerLabel={filterByPartnerLabel}
                filterByPartnerDefaultFilter={allPartnersToggleTag[0]}
                filterSeeMoreLabel={filterSeeMoreLabel}
                filterSeeLessLabel={filterSeeLessLabel}
                tags={newTagsPartnersObjects}
                hasObjectsTags
              /> */}
            </FiltersContainer>
          </Grid>
          <Grid item xs={12} sm={6} md={8} style={{padding: 0}}>
            <Keyplays
              allContentfulPartner={newAllPartnersObjects}
              keyplays={keyplays}
              currentTags={selectedTagsObjects}
              filterSeeMoreLabel={filterSeeMoreLabel}
              hashConditions={hashConditions}
            />
          </Grid>
        </Grid>
      </Section>
    </>
  )
}

PlayBook.propTypes = {
  data: shape({
    allContentfulpage: shape({
      edges: arrayOf(
        shape({
          node: shape({
            header: playbookHeaderPropTypes,
            partnerHeader: partnerHeaderPropTypes,
            filtersContainer: FiltersContainerPropTypes,
            filter: FilterPropTypes,
            keyplays: keyplaysPropTypes,
          }),
        }),
      ),
    }),
  }).isRequired,
  location: locationPropTypes.isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

PlayBook.defaultProps = {}

export const pageQuery = graphql`
  query templatePlaybook(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    allContentfulPlaybook(
      filter: {node_locale: {eq: $nodeLocale}, slug: {regex: $entityRegEx}}
    ) {
      edges {
        node {
          ...playBookFields
          sections {
            # hasPaddingTop
            # hasSmallWidth
            # hasPaddingSide
            # hasBorderBottom
            # hasSmallPadding
            # hasPaddingBottom
            # hasGreyBackground
            # hasRichTextPadding
            # hasVerySmallPadding

            __typename
            ... on ContentfulHero {
              name
              variant
              surtitle
              title
              description {
                description
              }
              callToAction {
                callToActionText
                callToActionLink
                callToActionArrow
              }
              hasPaddingBottom
              listItems {
                contentful_id
                primaryText {
                  primaryText
                }
                secondaryText {
                  secondaryText
                }
                tertiaryText {
                  tertiaryText
                }
                image {
                  gatsbyImageData
                  file {
                    url
                    fileName
                    contentType
                  }
                }
                thumbnail {
                  file {
                    url
                    fileName
                    contentType
                  }
                }
                cta
                ctaLink
              }
            }
          }
        }
      }
    }
    allContentfulMenuItem(
      filter: {name: {eq: "Partners"}, node_locale: {eq: $nodeLocale}}
    ) {
      edges {
        node {
          title
        }
      }
    }
    allContentfulTag(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          name {
            name
          }
          key_play {
            id
          }
        }
      }
    }
    allContentfulIndustry(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          name {
            name
          }
          key_play {
            id
          }
        }
      }
    }
    allContentfulDepartment(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          name {
            name
          }
          key_play {
            id
          }
        }
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    allContentfulKeyPlay(
      filter: {
        node_locale: {eq: $nodeLocale}
        countriesOfDestination: {
          elemMatch: {technicalName: {eq: $technicalName}}
        }
      }
    ) {
      nodes {
        ...playbookKeyPlayFields
      }
    }
    contentfulCountry(
      node_locale: {eq: $nodeLocale}
      technicalName: {eq: $technicalName}
    ) {
      id
      name
      ...countryDataFields
      ...partnerFields
      services {
        topLevelServices {
          entryId
          subLevelServices {
            entryId
          }
        }
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`

export default PlayBook
